import React, { Component } from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container, Form,
    Grid,
    GridColumn,
    GridRow,
    Image,
    Message
} from "semantic-ui-react";
import LoginForm from "../components/forms/login-form/login-form";
import icon from "../images/login/logo_black.png";
import iconcs from "../images/login/cs.png";
import { environmentMode, getMandatorySANDBOXMode } from "../components/lib/ad-manage-api-lib";
import { formatQuery } from "../controllers/utilities/string-utils";
import { Link } from "gatsby";
import { PasswordField, UsernameEmailField } from "../components/forms/form-fields";
import cookie from "react-cookies";
import iconlogin from "../images/login/login.png";
import input_back from "../images/login/input_back.png";
import "../styles/bg.css";
import Helmet from "react-helmet";
import hlogo from "../images/main/hlogo.png";
import { navigate } from "gatsby";
import i18next from "i18next";

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        let { gameId, attributionId, platform } = formatQuery(this.props.location.search);
        if (gameId) {
            cookie.save('_login_gameId', gameId);
        } else {
            gameId = cookie.load("_login_gameId");
        }
        if (attributionId) {
            cookie.save('_login_attributionId', attributionId);
        } else {
            attributionId = cookie.load("_login_gameId");
        }
        // 保存平台数据
        if ((platform) && (platform === "h5")) {
            cookie.save('_platform_operation', "login");
            cookie.save("_platform", platform);
        } else {
            cookie.save('_platform_operation', "unknown");
            cookie.save("_platform", "unknown");
        }
        this.state = {
            gameId: gameId
        }

    }
    async componentDidMount() {
        const backurl = this.props.location.pathname + this.props.location.search;
        cookie.save("csbackurl", backurl);
    }

    render() {
        let iconJgg = icon;
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT"){
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            }else{
                // docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }             
        }
        if (getMandatorySANDBOXMode(this.state.gameId)) {
            return (
                <Grid className="loginDiv" textAlign='center' style={{
                    height: "100vh",
                    padding: "3vh",
                    margin: "0",
                    backgroundColor: "#FFF"
                }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <React.Fragment>
                            <Container fluid>
                                <Card fluid style={{
                                    boxShadow: '0 1px 1px 0 #fff, 0 0 0 1px #fff'
                                }}>
                                    <div>
                                        <div style={{
                                            padding: "10px", height: "4.6rem",
                                            backgroundImage: `url(${input_back})`, backgroundSize: '100% 100%'
                                        }}>
                                            <UsernameEmailField
                                                style={{
                                                    width: "90%", height: "90%",
                                                    backgroundColor: "#2C2C2C", color: "#E4E4E4"
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                            padding: "10px", height: "4.6rem",
                                            backgroundImage: `url(${input_back})`, backgroundSize: '100% 100%'
                                        }}>
                                            <PasswordField
                                                style={{
                                                    width: "90%", height: "90%",
                                                    backgroundColor: "#2C2C2C", color: "#E4E4E4"
                                                }}
                                            />
                                        </div>
                                        <Message
                                            error
                                            content={i18next.t("SDK.Thegameisnotlaunched")}
                                        />
                                        <Button
                                            style={{
                                                height: "4rem",
                                                background: "transparent",
                                                backgroundImage: `url(${iconlogin})`, backgroundSize: '100% 100%'
                                            }}
                                            fluid size='large'>
                                            &nbsp;
                                        </Button>
                                    </div>
                                </Card>
                            </Container>
                        </React.Fragment>
                    </Grid.Column>
                </Grid>
            );
        } else {
            if ((this.state.gameId !== undefined) && (this.state.gameId !== "")) {
                return (
                    <div>

                        <Grid className="loginDiv" textAlign='center' style={{
                            height: "100vh",
                            padding: "3vh",
                            margin: "0",
                            backgroundColor: "#FFF"
                        }} verticalAlign='middle'>
                            {(mode === "LANDSCAPE") && (<Grid.Row>
                                <Grid.Column style={{ maxWidth: `${(mode === "PORTRAIT")?"450px":"360px"}` , paddingLeft: `${(mode === "PORTRAIT")?"0px":"45px"}`}}>
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <Image style={{
                                            marginTop:"0rem",
                                            marginBottom:"2rem",
                                            height: "2rem",
                                        }}
                                            centered src={hlogo} />
                                        <Image style={{
                                            height: "3rem",
                                            position: "absolute",
                                            top: "-0.5rem",
                                            right: "-10rem"
                                        }}
                                            onClick={() => {
                                                // const backurl = this.props.location.pathname + this.props.location.search;
                                                // cookie.save("csbackurl", backurl);
                                                const url = `/game-support/${this.props.location.search}`;
                                                // console.log(url);
                                                navigate(url);
                                            }}
                                            centered src={iconcs} />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>)}
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: `${(mode === "PORTRAIT")?"450px":"360px"}`, paddingLeft: `${(mode === "PORTRAIT")?"0px":"45px"}` }}>
                                    {(mode === "PORTRAIT") && (<div style={{ width: "100%", textAlign: "center" }}>
                                        <Image style={{
                                            marginTop:"-5rem",
                                            marginBottom: "5vh",
                                            height: "13vh"
                                        }} centered src={iconJgg} />
                                        <Image style={{
                                            marginTop:"-10rem",
                                            marginBottom: "2vh",
                                            height: "3.5rem",
                                            position: "absolute",
                                            top: "1rem",
                                            right: "-0.5rem"
                                        }}
                                            onClick={() => {
                                                // const backurl = this.props.location.pathname + this.props.location.search;
                                                // cookie.save("csbackurl", backurl);
                                                // console.log(backurl);
                                                const url = `/game-support/${this.props.location.search}`;
                                                // console.log(url);
                                                navigate(url);
                                            }}
                                            centered src={iconcs} />
                                    </div>)}


                                    <LoginForm />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid></div>
                );
            } else {
                return (
                    <Grid className="loginDiv" textAlign='center' style={{
                        height: "100vh",
                        padding: "3vh",
                        margin: "0",
                        backgroundColor: "#FFF"
                    }} verticalAlign='middle'>
                        <Grid.Column style={{ maxWidth: `${(mode === "PORTRAIT")?"450px":"360px"}` }}>
                        </Grid.Column>
                    </Grid>
                );
            }
        }
    }
};

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}